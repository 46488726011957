import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Gallery from '../components/Gallery'
import Helmet from '../components/Helmet'

const Portfolio = ({ data, path }) => {
  const { markdownRemark: post } = data
  const { frontmatter: { title, subtitle, year, description, private: isPrivate, gallery }, html } = post
  const hasInfo = (description && description.length > 0) || (html && html.length > 0)
  return (
    <React.Fragment>
      <Helmet
        postSEO
        postPath={path}
        postMeta={{
          portfolioTitle: title,
          subtitle,
          year,
          description: description
        }}
      />
      <Gallery
        isPrivate={isPrivate}
        subtitle={subtitle}
        title={title}
        year={year}
        hasInfo={hasInfo}
        description={description}
        gallery={gallery || []}
      />
    </React.Fragment>
  )
}

Portfolio.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
  path: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
}

export default Portfolio

export const pageQuery = graphql`
  query PortfolioByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        subtitle
        year
        description
        private
        gallery {
          images {
            url
            name
            aspectRatio
            isImage
          }
          caption
        }
      }
    }
  }
`
